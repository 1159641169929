import React, { FC, useEffect, useRef, useState } from 'react';
import s from './ModalMentor.module.scss'
import { MentorsProps } from '../SlideMentors/SlideMentors';
import { backUrl } from '../../../../../utils';
import { motion } from 'framer-motion'
import close_icon from '../../../../../assets/images/ic_round-close.svg'
import VideoJS from 'react-video-js-player';

const ModalMentor: FC<MentorsProps & { hide: () => void }> = ({ el, hide }) => {
    const playerRef = useRef<any>(null);
    const [isPlayerReady, setIsPlayerReady] = useState(false);

    const videoOptions = {
        controls: true,
        responsive: true,
        fluid: true,
        playbackRates: [0.5, 1, 1.5, 2],
        autoplay: false,
        preload: 'auto',
    };

    const handlePlayerReady = (player: any) => {
        if (player) {
            setIsPlayerReady(true);
        }
    };

    useEffect(() => {
        if (isPlayerReady && playerRef.current) {
            const player = playerRef.current.player;

            const handleTogglePlay = () => {
                if (player.paused()) {
                    player.play();
                } else {
                    player.pause();
                }
            };

            // Добавляем обработчик на клик по видео
            const videoElement = player.el();
            videoElement.addEventListener('touchstart', handleTogglePlay);
            videoElement.setAttribute('lang', 'ru-ru')

            const videoTag = videoElement.querySelector('video') as HTMLVideoElement;

            if (videoTag) {
                videoTag.setAttribute('playsinline', 'true'); // для Safari iOS
                videoTag.setAttribute('webkit-playsinline', 'true'); // старое свойство для iOS
                // Строгая настройка для iPhone
                videoTag.setAttribute('preload', 'none'); // Отключить предварительную загрузку
                videoTag.load(); // Явная загрузка видео
            }

            // Очистка обработчиков
            return () => {
                videoElement.removeEventListener('touchstart', handleTogglePlay);
            };
        }
    }, [isPlayerReady]);

    return (
        <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            className={s.wrapper}>
            <img onClick={hide} className={s.close} src={close_icon} alt="close icon" />
            <VideoJS
                ref={playerRef}
                options={videoOptions}
                poster={backUrl + el.image_url}
                src={backUrl + el.video_url}
                className={`${s.video_js} ${s.vjs_big_play_centered}`}
                onReady={handlePlayerReady}
            />
            <motion.h2
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.5 }}
                className={s.mentor_name}>{el.name}</motion.h2>
            <motion.p
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                className={s.mentor_descr}>{el.description}</motion.p>
        </motion.div>
    );
};

export default ModalMentor;