import React, { useEffect } from 'react';
import './App.scss'
import './i18n'
import Header from './Components/Header/Header';
import Main from './Components/Main/Main';
import Footer from './Components/Footer/Footer';
import MouseParticles from 'react-mouse-particles'

const App = () => {
  // const { t, i18n } = useTranslation()

  useEffect(() => {
    // Ищем элемент по id, который начинается с 'mouse_ps_'
    const mouseParticlesContainer = document.querySelector("[id^='mouse_ps_']") as HTMLDivElement;

    if (mouseParticlesContainer) {
      mouseParticlesContainer.style.zIndex = "-1"; // Размещаем под контентом
    }
  }, []);


  return (
    <div style={{
      // filter: isBlurred ? 'blur(10px) brightness(10%)' : 'none',
      // transition: 'filter 0.3s ease',
      // backgroundColor: isBlurred ? 'black' : 'transparent',
      // minHeight: '100vh'
    }}>
      <div style={{ position: 'relative' }}>
        <MouseParticles
          g={0.7}
          num={7} // Увеличиваем количество частиц
          radius={7} // Радиус частиц
          life={0.7} // Дольше жизни
          v={1.0} // Скорость движения
          color={["#FF8615", "#42BC2B", "#4F57CE", "#9221d8"]} // Массив цветов
          alpha={0.4} // Прозрачность
          level={5}
        />
      </div>
      <div style={{ position: 'relative', zIndex: 2 }}>
        <Header />
        <Main />
        <Footer />
      </div>
    </div>
  );
};

export default App;