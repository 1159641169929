import React, { FC, useEffect } from 'react';
import ProgramElements from './ProgramElements/ProgramElements';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { scrollToTop } from '../../utils';
import { fetchByModuleThree } from '../../redux/slices/modulesSlice';
import { LayoutGroup } from 'framer-motion';
import CardInfo from '../../Components/CardInfo/CardInfo';
import CourseOverview from '../../Components/CourseOverview/CourseOverview';
import LearningFormat from '../../Components/LearningFormat/LearningFormat';
import StudyPlan from '../../Components/StudyPlan/StudyPlan';
import ExpectedResult from '../../Components/ExpectedResult/ExpectedResult';
import FAQ from '../../Components/FAQ/FAQ';
import ContactForm from '../../Components/ContactForm/ContactForm';
import { Helmet } from 'react-helmet-async';

const ModuleThree: FC = () => {
    const { module_three } = useAppSelector(state => state.mmodules)
    const dispatch = useAppDispatch()
    const moduleNum: string = '3'

    useEffect(() => {
        scrollToTop()
        if (!module_three) {
            dispatch(fetchByModuleThree())
        }
    }, [dispatch])

    return (
        <>
            <Helmet>
                <title>Модуль 3 | Клуб новаторов</title>
                <meta name="description" content="Создание и совершенствование собственной стратегии трейдинга. Разработка эффективных стратегий для успешной торговли с адаптацией к условиям рынка." />
                <meta property="og:title" content={`${module_three?.title} | Клуб новаторов`} />
                <meta name="og:description" content={`${module_three?.title}, ${module_three?.description}`} />
                <meta property="og:url" content="https://itsplatform.net/module-tree" />
                <link rel="canonical" href="https://itsplatform.net/module-tree" />
            </Helmet>
            {
                module_three &&
                <LayoutGroup id='module_three'>
                    <CardInfo
                        moduleNum={moduleNum}
                        title={module_three?.title}
                        amount={module_three?.lesson_amount}
                        duration={module_three?.duration}
                        description={module_three?.description}
                        main_banner={module_three?.main_banner}
                    />
                    <div className='container'>
                        <ProgramElements general_programs={module_three?.general_programs} />
                        <CourseOverview moduleNum={moduleNum} learnings={module_three?.learnings} />
                        <LearningFormat moduleNum={moduleNum} format_learnings={module_three?.format_learnings} />
                        <StudyPlan
                            moduleNum={moduleNum}
                            for_who={module_three?.for_who}
                            for_who_answer={module_three?.for_who_answer}
                            weeks={module_three?.weeks}
                            amount={module_three?.lesson_amount}
                            duration={module_three?.duration}
                        />
                        <ExpectedResult
                            moduleNum={moduleNum}
                            result_bg={module_three?.result_bg}
                            results={module_three?.results}
                        />
                        {
                            module_three.faq.length > 0 &&
                            <FAQ moduleNum={moduleNum} arr={module_three.faq} />
                        }
                    </div>
                    <ContactForm moduleNum={moduleNum} />
                </LayoutGroup>
            }
        </>
    );
};

export default ModuleThree;