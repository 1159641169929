import React, { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../../Pages/Home/Home';
import Program from '../../Pages/Program/Program';

import { useAppDispatch } from '../../redux/hooks';
import { fetchByAllInfo } from '../../redux/slices/infoSlice';
import ModuleOne from '../../Pages/ModuleOne/ModuleOne';
import ModuleTwo from '../../Pages/ModuleTwo/ModuleTwo';
import ModuleThree from '../../Pages/ModuleThree/ModuleThree';
import InfoPage from '../../Pages/InfoPage/InfoPage';

const Main: FC = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByAllInfo())
    }, [dispatch])

    return (
        <main>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/program' element={<Program />} />
                <Route path='/module-one' element={<ModuleOne />} />
                <Route path='/module-two' element={<ModuleTwo />} />
                <Route path='/module-three' element={<ModuleThree />} />
                <Route path='/info/:name' element={<InfoPage />} />
            </Routes>
        </main>
    );
};

export default Main;