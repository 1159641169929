import React, { FC, useState, useEffect } from "react";
import s from "./Header.module.scss";
import { Link as RouterLink } from "react-router-dom";
import accLogo from "../../assets/images/account.png";
import Navigation from "../Navigation/Navigation";
import { useAppSelector } from "../../redux/hooks";
import { backUrl } from "../../utils";
import BurgerMenu from "./BurgerMenu/BurgerMenu";

const Header: FC = () => {
    const { info } = useAppSelector((state) => state.info);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY && currentScrollY > 100) {
            // Скролл вниз, скрываем хедер
            setIsVisible(false);
        } else {
            // Скролл вверх, показываем хедер
            setIsVisible(true);
        }

        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    return (
        <>
            <BurgerMenu />
            <header className={`${s.header} ${isVisible ? s.visible : s.hidden}`}>
                <div className={`container ${s.header_wrapper}`}>
                    <RouterLink className={s.logo} to={"/"}>
                        {info && <img src={backUrl + info?.logo} alt="logo" />}
                    </RouterLink>
                    <nav className={s.navigation}>
                        <Navigation />
                    </nav>
                    <a
                        className={s.account_link}
                        href="https://video.itsplatform.net"
                    >
                        <img src={accLogo} alt="Переход на платформу" />
                        Кабинет
                    </a>
                </div>
            </header>
        </>
    );
};

export default Header;