import React, { FC } from 'react';
import s from './Footer.module.scss'
import { Element } from 'react-scroll';
import Contacts from './Contacts/Contacts';
import Navigation from '../Navigation/Navigation';
import { useAppSelector } from '../../redux/hooks';
import { backUrl } from '../../utils';
import { Link } from 'react-router-dom';

const Footer: FC = () => {
    const { info } = useAppSelector(state => state.info)

    return (
        <Element className={` ${s.bg_fone}`} name='contacts'>
            <footer >
                <div className={`container ${s.footer}`}>
                    <div className={s.nav_wrapper}>
                        {info && <img className={s.logo} src={backUrl + info?.logo} alt="logo" />}

                        <nav className={s.navigation}>
                            <Navigation />
                        </nav>
                    </div>

                    <Contacts />
                </div>
                <div className={`${s.copyright_block} container`}>
                    <p className={s.left_text}>© 2000 - {new Date().getFullYear()} компания Новатор. Все права защищены.</p>
                    <Link to={'/info/privacy_policy'}>Политика конфиденциальности</Link>
                </div>
            </footer>
        </Element>
    );
};

export default Footer;