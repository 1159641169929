import React, { ChangeEventHandler, FC, FormEventHandler, useEffect, useState } from 'react';
import s from './FeedbackForm.module.scss'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { validateEmail } from '../../../utils';
import { Link } from 'react-router-dom';
import close_icon from '../../../assets/images/ic_round-close.svg'
import { motion } from 'framer-motion'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { closeSuccess, fetchByNewFeedback, setFeedbackError } from '../../../redux/slices/infoSlice';
import { IFeedbackUser } from '../../../redux/types';
import { getLS } from '../../../LS';
import ModalHoc from '../../ModalHoc/ModalHoc';
import SuccessModal from '../../SuccessModal/SuccessModal';

interface FeedbackFormProps {
    hide: () => void
}

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

const FeedbackForm: FC<FeedbackFormProps> = ({ hide }) => {
    const { loading, feedback_error, success_modal } = useAppSelector(state => state.info)
    const dispatch = useAppDispatch()
    const [isBlocked, setIsBlocked] = useState(false);
    const [userInfo, setUserInfo] = useState<IFeedbackUser>({
        name: '',
        email: '',
        phone: '',
    })



    const handleValues: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value })
    }

    const checkUserInfo = () => {
        if (userInfo.name.trim().length && !validateEmail(userInfo.email) && isPhoneValid(userInfo.phone)) {
            return false
        } else {
            return true
        }
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        if (!checkUserInfo() && !isBlocked) {
            dispatch(fetchByNewFeedback(userInfo))

        } else {
            dispatch(setFeedbackError('Вы уже отправляли форму! Попробуйте позже!'))
        }
    }

    useEffect(() => {
        const lastSubmitTime = getLS('lastSubmitTime');

        if (lastSubmitTime) {
            const timeDiff = Date.now() - parseInt(lastSubmitTime);
            if (timeDiff < 2 * 60 * 60 * 1000) {
                setIsBlocked(true);
                dispatch(setFeedbackError('Вы уже отправляли форму! Попробуйте позже!'))
            }
        }

        return () => {
            dispatch(setFeedbackError(null))
            dispatch(closeSuccess())
        }

    }, [dispatch]);

    return (
        <>
            <motion.form
                onSubmit={handleSubmit}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
                className={s.form}>
                <img onClick={hide} className={s.close} src={close_icon} alt="close icon" />
                <motion.h4
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.3 }}
                    className={s.subtitle}>Первые шаги осознаности на бирже</motion.h4>
                <motion.h2
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.3 }}
                    className={s.title}>Введите ваши данные</motion.h2>
                <motion.input
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    required
                    value={userInfo.name}
                    onChange={handleValues}
                    name='name' type="text" placeholder='Имя' />
                <motion.input
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                    required
                    value={userInfo.email}
                    onChange={handleValues}
                    name='email' type="email" placeholder='Email' />
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.55 }}
                >
                    <PhoneInput
                        required={true}
                        style={{ height: 65 }}
                        className={`phone ${s.phone}`}
                        defaultCountry="kg"
                        value={userInfo.phone}
                        onChange={(phone) => setUserInfo({ ...userInfo, phone })}
                    />
                </motion.div>
                {feedback_error && <motion.span
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.6 }}
                    className='error'>{feedback_error}</motion.span>}
                <motion.button
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.6 }}
                    disabled={checkUserInfo() || isBlocked || loading}
                    className={s.submit}>Оставить заявку</motion.button>
                <motion.p
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                    className={s.аgreement}>
                    Нажимая на кнопку, я подтверждаю, что я прочел(-ла) и принимаю
                    <Link to={'/info/terms_of_service'}> Условия оказания услуг </Link>
                    и
                    <Link to={'/info/privacy_policy'}> Политику конфиденциальности</Link>
                </motion.p>
            </motion.form>
            {
                success_modal &&
                <ModalHoc>
                    <SuccessModal hide={hide} />
                </ModalHoc>
            }
        </>
    );
};

export default FeedbackForm;