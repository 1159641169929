import React, { FC, useEffect, useRef, useState } from 'react';
import s from './AboutUS.module.scss'
import poster from '../../../../assets/images/poster.png'
import play_btn from '../../../../assets/images/play.png'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { Element } from 'react-scroll';
import { useAppSelector } from '../../../../redux/hooks';
import ModalHoc from '../../../../Components/ModalHoc/ModalHoc';
import { backUrl } from '../../../../utils';
import ShowFeedback from '../../../../Components/ShowFeedback/ShowFeedback';
import VideoJS from 'react-video-js-player';
import 'video.js/dist/video-js.css';
import 'video.js/dist/lang/ru.json';

const AboutUS: FC = () => {
    const [show, setShow] = useState(false)
    const { info } = useAppSelector(state => state.info)
    const playerRef = useRef<any>(null);
    const [isPlayerReady, setIsPlayerReady] = useState(false);

    const handleClose = () => {
        setShow(false)
    }

    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    const videoOptions = {
        controls: true,
        responsive: true,
        fluid: true,
        playbackRates: [0.5, 1, 1.5, 2],
        autoplay: false,
        preload: 'auto',
    };

    const handlePlayerReady = (player: any) => {
        if (player) {
            setIsPlayerReady(true);
        }
    };

    useEffect(() => {
        if (isPlayerReady && playerRef.current) {
            const player = playerRef.current.player;

            const handleTogglePlay = () => {
                if (player.paused()) {
                    player.play();
                } else {
                    player.pause();
                }
            };

            // Добавляем обработчик на клик по видео
            const videoElement = player.el();
            videoElement.addEventListener('touchstart', handleTogglePlay);
            videoElement.setAttribute('lang', 'ru-ru')

            const videoTag = videoElement.querySelector('video') as HTMLVideoElement;

            if (videoTag) {
                videoTag.setAttribute('playsinline', 'true'); // для Safari iOS
                videoTag.setAttribute('webkit-playsinline', 'true'); // старое свойство для iOS
                // Строгая настройка для iPhone
                videoTag.setAttribute('preload', 'none'); // Отключить предварительную загрузку
                videoTag.load(); // Явная загрузка видео
            }

            // Очистка обработчиков
            return () => {
                videoElement.removeEventListener('touchstart', handleTogglePlay);
            };
        }
    }, [isPlayerReady]);

    return (
        <>
            <Element name='about_us'>
                <section className={`container ${s.wrapper}`}>
                    <h2 className={s.mobile_title}>О нас</h2>
                    <motion.div
                        onClick={() => setShow(true)}
                        ref={ref}
                        initial={{ opacity: 0, x: -150 }}
                        animate={inView ? { opacity: 1, x: 0 } : {}}
                        transition={{ type: 'keyframes', stiffness: 300, duration: 0.8 }}
                        className={s.img_wrapper}
                    >
                        {!show && <img className={s.poster} src={poster} alt="poster" />}
                        <img className={s.play} src={play_btn} alt="play button" />
                    </motion.div>
                    <div className={s.info_wrapper}>
                        <motion.h2
                            initial={{ opacity: 0, y: 50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.8 }}
                            className={s.title}>О нас</motion.h2>
                        <motion.p
                            initial={{ opacity: 0, y: 50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.8, delay: 0.3 }}
                            className={s.description}>
                            {info?.about_description}
                        </motion.p>
                        <ShowFeedback>Записаться на курс</ShowFeedback>
                    </div>
                </section>
            </Element>
            {
                info && show &&
                <ModalHoc hide={handleClose} show={show} >
                    <VideoJS
                        ref={playerRef}
                        options={videoOptions}
                        poster={backUrl + info?.about_video}
                        src={backUrl + info?.about_video}
                        onReady={handlePlayerReady}
                        className={`${s.video_js} ${s.vjs_big_play_centered}`}
                    />
                </ModalHoc>
            }
        </>
    );
};

export default AboutUS;