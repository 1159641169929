import React, { FC, useEffect, useState } from 'react';
import s from './WeekItem.module.scss'
import { ILessons } from '../../../../redux/types';
import { motion } from 'framer-motion'
import closeBtn from '../../../../assets/images/ic_round-close.svg';

interface WeekItemProps {
    lesson: ILessons
}

const WeekItem: FC<WeekItemProps> = ({ lesson }) => {
    const { lesson_themes, title } = lesson
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCardClick = () => {
        if (window.innerWidth <= 767) {
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleEscape = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            setIsModalOpen(false);
        }
    };

    const checkInnerWidth = () => {
        const client = window.innerWidth
        if (client > 992) {
            return 0.5
        } else if (client < 992) {
            return 1
        }
    }

    const itemWeek = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: checkInnerWidth()
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            window.history.pushState(null, '', window.location.href);


            window.onpopstate = () => {
                setIsModalOpen(false);
            };

            const handleEscape = (e: KeyboardEvent) => {
                if (e.code === 'Escape') {
                    setIsModalOpen(false);
                }
            };
            document.addEventListener('keydown', handleEscape);

            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            window.onpopstate = null;
            document.removeEventListener('keydown', handleEscape);
        };
    }, [isModalOpen, setIsModalOpen]);

    return (
        <motion.div
            variants={itemWeek}
            whileHover={{ opacity: 1, scale: [null, 1.15, 1.1] }}
            onClick={handleCardClick}
            transition={{ duration: 0.2 }}
            className={s.card}>
            <h2>{title}</h2>
            <div
                className={s.card_info}
            >
                <div className={s.info_item}>
                    <ul className={s.dot}>
                        {
                            lesson_themes.length > 0 &&
                            lesson_themes.map(el => (
                                <li key={el.id}>{el.title}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>

            {isModalOpen && (
                <div
                    className={s.modal}
                    onClick={(e) => {
                        e.stopPropagation()
                        closeModal()
                    }}
                >
                    <div onClick={(e) => e.stopPropagation()} className={s.container}>
                        <div className={s.info_item}>
                            <ul className={s.dot}>
                                {
                                    lesson_themes.length > 0 &&
                                    lesson_themes.map(el => (
                                        <li key={el.id}>{el.title}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        <button aria-label='Закрыть попап' className={s.close} onClick={closeModal}>
                            <img src={closeBtn} alt="close icon" />
                        </button>
                    </div>
                </div>

            )}
        </motion.div>
    );
};

export default WeekItem;