import React, { FC, useEffect } from 'react';
import s from './InfoPage.module.scss'
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { scrollToTop } from '../../utils';

const InfoPage: FC = () => {
    const { name } = useParams()
    const { info } = useAppSelector(state => state.info)

    useEffect(() => {
        scrollToTop()
    }, [name])

    return (
        <div className={`${s.wrapper} container`}>
            {
                name === 'terms_of_service' &&
                <>
                    <h1>Условия оказания услуг.</h1>
                    <p>{info?.terms_of_service}</p>
                </>
            }
            {
                name === 'privacy_policy' &&
                <>
                    <h1>Политика конфиденциальности.</h1>
                    <p>{info?.policy}</p>
                </>
            }
        </div>
    );
};

export default InfoPage;