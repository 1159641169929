import React, { FC, useEffect, useState } from 'react';
import s from './BurgerMenu.module.scss'
import { NavLink, Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import { backUrl } from '../../../utils';
import { scroller, Link as ScrollLink } from 'react-scroll';
import ModulesMenu from '../ModulesMenu/ModulesMenu';

const BurgerMenu: FC = () => {
    const [isClosed, setIsClosed] = useState(false);
    const { info } = useAppSelector(state => state.info)
    const navigate = useNavigate();

    const scrollToSection = (section: string) => {
        navigate('/');

        setTimeout(() => {
            scroller.scrollTo(section, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
            });
        }, 100);
    };

    const handleClick = (section?: string) => {
        setIsClosed(!isClosed);
        section && scrollToSection(section)
    };

    useEffect(() => {
        if (isClosed) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }

    }, [navigate, isClosed])
    return (
        <div className={s.container}>
            <div className={s.header}>
                <button onClick={() => handleClick()} className={isClosed ? s.menu_btn_active : s.menu_btn}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <div className={`${s.wrapper} ${isClosed && s.active}`}>
                <RouterLink onClick={() => setIsClosed(false)} className={s.logo} to='/'>
                    {info?.logo && <img src={backUrl + info?.logo} alt="logo" />}
                </RouterLink>
                <nav>
                    <ul>
                        <li>
                            <button className={s.menu_link}
                                onClick={() => handleClick('about_us')}>О нас</button>
                        </li>
                        <li>
                            <NavLink onClick={() => handleClick()} to={'/program'}
                                className={({ isActive }) => isActive ? `${s.menu_link} ${s.active}` : s.menu_link}
                            >Программа</NavLink>
                        </li>
                        <li>
                            <button className={s.menu_link}
                                onClick={() => handleClick('mentors')}>Менторы</button>
                        </li>
                        <li>
                            <button className={s.menu_link}
                                onClick={() => handleClick('reviews')}>Отзывы</button>
                        </li>
                        <li>
                            <ScrollLink
                                onClick={() => handleClick()}
                                activeClass={s.active}
                                className={s.menu_link}
                                to={"contacts"}
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                            >Контакты</ScrollLink>
                        </li>
                        <ModulesMenu setIsClosed={setIsClosed} />
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default BurgerMenu;